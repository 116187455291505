// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---gatsby-theme-replica-src-templates-archive-tsx": () => import("./../../../../gatsby-theme-replica/src/templates/archive.tsx" /* webpackChunkName: "component---gatsby-theme-replica-src-templates-archive-tsx" */),
  "component---gatsby-theme-replica-src-templates-categories-tsx": () => import("./../../../../gatsby-theme-replica/src/templates/categories.tsx" /* webpackChunkName: "component---gatsby-theme-replica-src-templates-categories-tsx" */),
  "component---gatsby-theme-replica-src-templates-category-tsx": () => import("./../../../../gatsby-theme-replica/src/templates/category.tsx" /* webpackChunkName: "component---gatsby-theme-replica-src-templates-category-tsx" */),
  "component---gatsby-theme-replica-src-templates-home-tsx": () => import("./../../../../gatsby-theme-replica/src/templates/home.tsx" /* webpackChunkName: "component---gatsby-theme-replica-src-templates-home-tsx" */),
  "component---gatsby-theme-replica-src-templates-post-tsx": () => import("./../../../../gatsby-theme-replica/src/templates/post.tsx" /* webpackChunkName: "component---gatsby-theme-replica-src-templates-post-tsx" */),
  "component---gatsby-theme-replica-src-templates-tag-tsx": () => import("./../../../../gatsby-theme-replica/src/templates/tag.tsx" /* webpackChunkName: "component---gatsby-theme-replica-src-templates-tag-tsx" */),
  "component---gatsby-theme-replica-src-templates-tags-tsx": () => import("./../../../../gatsby-theme-replica/src/templates/tags.tsx" /* webpackChunkName: "component---gatsby-theme-replica-src-templates-tags-tsx" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */)
}

